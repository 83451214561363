.blender .img{
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.blender .img img{
    width: 75vw;
}
.blender .img video{
    width: 75vw;
}
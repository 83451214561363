a{
    color: black;
    text-decoration: none;
    cursor: url('../images/favicon.ico'), auto;
  
}

.webgl{
    top: 0;
    left: 0;
    outline: none;
}

.links{
    position: absolute;
    margin-top: 39%;
    margin-bottom: 0;
    right: 1vw;
    z-index: 3;
}

.link{
    width: 5vw;
    height: 5vw;
    margin-bottom: 0;
    border-radius: 50%;  

    display:  flex;
    justify-content: center;
    align-items: center;

    background-color: white;
}
.material-icons{ font-size: 2vw; }


.projects{
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
}

.projects h2{
    transition: 200ms ease-in-out;
}

.video1{
    margin: 0;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .title{
        position: absolute;
        margin-top: 0;
        margin-bottom: 0;
        inset: 0;
        z-index: 2;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.205); 
    }
    .title h2{
        margin-left: 1vw;
        margin-top: 14%;
        font-size: 3vw;
    }
    .title h3{
        margin-left: 1vw;
        margin-top: 1vh;
        font-size: 1.5vw;
    }
    .title p{
        margin-left: 1vw;
        margin-top: 1vh;
        width: 20%;
        font-size: 0.9vw;
    }
}
.title.fade{
    margin-bottom: 0
}
.projects > .title.fade{
    opacity: 1;
}

.projects:hover > .title.fade{
    opacity: 0;
}

.extra{
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    margin-bottom: 1vh;
}
.extra h2{
    font-size: 4.1vw;
    margin-left: 1vw;
    margin-top: 1vh;


}
.extra a:hover{
    color: darkgray;
}




/**
* MOBILE
*/
@media screen and (max-width: 800px) {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .title h2{
            margin-top: 10%;
            font-size: 7vw;
        }
        .title h3{
            display: none;
            font-size: 4vw;
        }
        .title p{
            font-size: 2.5vw; 
            width: 80%;

        }

        .material-icons{ 
            font-size: 4vw; 
        }

        .link{
            width: 7vw;
            height: 7vw;
        }
    }


}

.extras{
    width: 100vw;
    height: 76.5vh;
    background: #ffffff;
    color: #000000;
    z-index: -3;
    font-family: Helvetica, sans-serif;
    font-size: 8vw;
  
    display: flex;
    flex-direction: column;
}

.extras div{
    margin-left: 1vw;    
}

.extras a{
    display: flex;
}

.blend{
    height: 21vh;
    width: auto;
    margin-left: 2vw;

    visibility: hidden;
    opacity: 0;
}

.touche{
    height: 21vh;
    width: auto;
    margin-left: 2vw;

    visibility: hidden;
    opacity: 0;

}

.aBlend:hover .blend {
    visibility: visible;
    opacity: 1;
}

.aTouch:hover .touche {
    visibility: visible;
    opacity: 1;
}
.footer{
    display: flex;
    margin-top: 1vh;
    margin-bottom: 1vh;
}
.footer h2{
    font-size: 4.1vw;
    margin-left: 1vw;

}
.footer a:hover{
    color: darkgray;
}
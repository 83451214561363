* {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}



/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  cursor: url('../public/favicon.ico'), auto;
}